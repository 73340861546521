<template>
  <div class="board-holder">
    <div class="board-div board-first">
      <div class="board-img-holder"  data-aos="flip-left">
          <img class="board-img" src="~@/assets/sanoj.png" />
      </div>
      <div class="board-text">
        <h2 data-aos="fade-up">Sanoj (OJ) Dhamindranath</h2>
        <h5 data-aos="fade-up">CEO</h5>
        <p data-aos="fade-up">
          Sanoj has over 20 years of progressive executive management
          experience. He oversees the execution of Ploutus’ mission, vision, and
          strategy for the group of companies. In addition to leading the
          overall financial and operational direction of the Group, he manages
          key client relationships and leads select endeavors in the areas of
          finance, construction and infrastructure, technology, oil and gas,
          renewable energy, mobile telecommunication, commodity trading, food
          distribution, consultancy services, mining, precious metals, health,
          travel and tourism.
        </p>
      </div>
    </div>
    <div class="board-div board-second">
      <div class="board-img-holder" data-aos="flip-right">
          <img class="board-img" src="~@/assets/naveen.png" />
      </div>
      <div class="board-text">
        <h2 data-aos="fade-up">Naveen Weerasena</h2>
        <h5 data-aos="fade-up">DIRECTOR BUSINESS DEVELOPMENT</h5>
        <p data-aos="fade-up">
          A high-impact professional leader with over 25 years of management
          experience, project management, business analysis, finance &
          budgeting, and multi-domain knowledge.
          <br>
          He has years of experience in
          the information technology field, as a Software developer using
          Account / Project Manager platforms, and in areas such as IT
          infrastructure, data & VOIP networks, IT security systems, and
          centralized & decentralized server management.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Board",
};
</script>
<style>
    .board-holder{
        text-align: left;
    }

    .board-div{
        display: flex;
        flex-direction: row;
    }

    .board-first{
        margin-bottom: 20px;
    }

    .board-second{
        flex-direction: row-reverse;
    }

    .board-img{
        width: 300px;
        border: 1px dashed #EAAF08;
    }

    .board-first .board-img-holder{
        margin-right: 20px;
    }

    .board-second .board-img-holder{
        margin-left: 20px;
    }

    .board-text h2{
        color: #EAAF08;
        margin: 0 0 10px;
        font-size: 30px;
    }

    .board-text h5{
        margin: 0 0 20px;
        font-size: 18px;
    }

    .board-text p{
        margin: 0;
        font-size: 20px;
    }

@media screen and (max-width: 600px) {

    .board-holder {
    text-align: center;
    }

    .board-div{
        flex-direction: column;
    }

    .board-second{
        flex-direction: column;
    }

    .board-img{
        width: auto;
        max-width: 100%;
    }

    .board-first .board-img-holder {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .board-second .board-img-holder {
        margin-left: 0;
        margin-bottom: 20px;
    }

    .board-text h2 {
        font-size: 22px;
    }

    .board-text h5 {
        font-size: 16px;
    }

    .board-text p {
        font-size: 14px;
    }

}
</style>