<template>
  <div id="app">
    <div class="theloader" v-if="loading">
      <div class="loader"></div>
    </div>
    <div class="theapp" v-bind:class="{ noop: loading}">
      <header id="site-header" class="nav is-fixed">
        <scrollactive class="topnav" id="myTopnav" v-bind:class="{ responsive: isActive}">
          <a href="javascript:void(0);" class="logo-holder"><img class="logo" src="~@/assets/logo.png" /></a>
          <a href="#section-1" class="scrollactive-item" v-on:click="closeNav">Home</a>
          <a href="#section-2" class="scrollactive-item" v-on:click="closeNav">Comodities</a>
          <a href="#section-3" class="scrollactive-item" v-on:click="closeNav">Our Team</a>
          <a href="#section-4" class="scrollactive-item" v-on:click="closeNav">Contact Us</a>
          <a href="javascript:void(0);" class="icon" v-on:click="navFunc">{{isActive ? 'X' : '☰'}}</a>
        </scrollactive>
      </header>
      <main>
        <section id="section-1" class="">
          <div class="container">
            <About />
          </div>
        </section>
        <section id="section-2" class="section">
          <div class="container">
            <h2 class="section-heading" data-aos="zoom-in">Comodities</h2>
          </div>
          <Home />
        </section>
        <section id="section-3" class="section">
          <div class="container">
            <h2 class="section-heading" data-aos="zoom-in">Our Team</h2>
            <Board />
          </div>
        </section>
        <section id="section-4" class="section">
          <div class="container">
            <h2 class="section-heading" data-aos="zoom-in">Contact Us</h2>
            <Contact />
          </div>
        </section>
      </main>
      <footer>
        <div class="container footer-top-border">
          <div class="footer-holder">
            <div class="footer-logo footer-child separator footer-child-flex" data-aos="flip-right">
              <img src="~@/assets/goldway-logo.jpeg" /><br>
              <!-- <a href="https://ploutus.com/" target="_blank"><img src="~@/assets/ploutus-logo.png" /></a> -->
            </div>
            <div class="footer-links footer-child separator" data-aos="flip-up">
              <h4 class="footer-heading">Links</h4>
              <scrollactive class="footer-menu">
                <div class="footer-link-holder"><a href="#section-1" class="scrollactive-item">Home</a></div>
                <div class="footer-link-holder"><a href="#section-2" class="scrollactive-item">Comodities</a></div>
                <div class="footer-link-holder"><a href="#section-3" class="scrollactive-item">Our Team</a></div>
                <div class="footer-link-holder"><a href="#section-4" class="scrollactive-item">Contact Us</a></div>
              </scrollactive>
            </div>
            <div class="footer-address footer-child separator" data-aos="flip-up">
              <h4 class="footer-heading">Address</h4>
              <p>Floor #301 H Hotel</p>
              <p>1 Sheikh Zayed Road</p>
              <p>Dubai, UAE</p>
              <p>Tel: +971 4 239 0400</p>
            </div>
            <div class="footer-location footer-child" data-aos="flip-left">
              <h4 class="footer-heading">Loction</h4>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14436.790969835114!2d55.2871273!3d25.2302639!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x42520876c7be8ab1!2sThe%20H%20Dubai!5e0!3m2!1sen!2sae!4v1615190269244!5m2!1sen!2sae" width="100%" height="" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>
          <div class="signature">Copyright © 2021 GoldWay, LLC | All Rights Reserved</div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Home from "./components/Home.vue";
import About from './components/About.vue'
import Board from './components/Board.vue'
import Contact from './components/Contact.vue'

export default {
  name: "App",
  components: {
    Home,
    About,
    Board,
    Contact
  },
  data(){
    return{
      isActive: false,
      loading: true
    }
  },
  methods:{
    navFunc(){
      this.isActive = !this.isActive
    },
    closeNav(){
      this.isActive = false
    },
  },
  created(){
      setTimeout(() => {
        this.loading = false
      },2000);
    }
};
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000; 
}

/* ::-webkit-scrollbar-track:hover {
  background: #fff; 
} */
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #EAAF08; 
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #000; 
} */

body{
  padding:0;  
  margin: 0 auto;
  background-color: #000;
  width: 100%;
  max-width: 1600px;
}

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

.noop{
  opacity: 0;
}

.theloader{
  display: flex;
  position: absolute;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0,0,0);
  z-index: 99;
}

.loader{
  margin: 0 auto;
  width: 120px;
  height: 120px;
  border: 16px solid #000;
  border-radius: 50%;
  border-top: 16px solid #EAAF08;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 } 
  to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
  from{ bottom:-100px; opacity:0 } 
  to{ bottom:0; opacity:1 }
}


.nav.is-fixed {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
}
.nav.is-fixed .is-active {
  text-decoration: none;
  color: #EAAF08;
}

.topnav {
  overflow: hidden;
  background-color: #000;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a.logo-holder{
  padding: 12px 0 0 16px;
}

.logo{
  width: 30px;
}

.topnav a:hover {
  color: #EAAF08;
}

.topnav a:focus {
  color: #fff;
}

.topnav a.is-active {
  color: #EAAF08;
}

.topnav .icon {
  display: none;
  color: #fff;
}

.icon:hover, .icon:focus {
  color: #EAAF08;
}

.sticky-rates{
  width: 116px;
  height: 140px;
  overflow: hidden;
  margin: 0 0 0 auto;
}

#section-1 {
  padding-top: 48px;
}

.container{
  padding: 45px 15px 0;
  max-width: 1140px;
  margin: 0 auto;
}

.section-heading{
  text-align: left;
  font-size: 35px;
  color: #EAAF08;
  margin-top: 0;
}

.footer-top-border{
  border-top: 1px dashed #EAAF08;
  margin-top: 50px;
}

.footer-holder{
  display: flex;
  flex-direction: row;
}

.footer-child{
  width: 25%;
  padding: 5px 10px;
}

.footer-child-flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo img{
  width: auto;
  max-width: 100%;
}

/* .footer-menu{
  text-align: center;
} */

.footer-link-holder{
  padding-top: 10px;
}

.footer-heading{
  margin: 0 0 10px;
  color: #EAAF08;
  font-size: 20px;
}

.footer-menu a{
  color: #fff;
  font-size: 16px;
  text-decoration: none;
}

.footer-menu a:hover{
  color: #EAAF08;
}

.footer-address p{
  font-size: 16px;
  margin: 0;
  padding-top: 10px;
}

.separator{
  border-right: 1px dashed #EAAF08;
}

.signature{
  margin: 10px;
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .topnav a:not(.is-active, .logo-holder) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }

  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .section-heading{
    text-align: center;
    font-size: 25px;
  }

  .overlay-text {
    font-size: 25px;
  }

  .footer-top-border {
    margin-top: 20px;
  }

  .footer-holder {
    flex-direction: column;
  }

  .footer-child {
    width: 100%;
    padding: 5px 0;
  }

  .footer-menu a, .footer-address p{
    font-size: 14px;
  }

  .separator {
    border-right: 0;
    border-bottom: 1px dashed #EAAF08;
  }

  .footer-logo img {
    width: 300px;
  }

  iframe{
    width: 100%;
  }

  .signature {
    font-size: 12px;
  }

}
</style>
