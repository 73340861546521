<template>
  <div>
    <VueSlickCarousel v-bind="settings">
      <div class="slide-img-holder">
        <img class="slide-img" src="~@/assets/gsp.jpg" />
        <div class="overlay">
          <div class="overlay-text-holder">
            <div class="overlay-text">Precious Metal Trading</div>
            <div class="overlay-desc">
              Precious metals, such as gold, silver and platinum, are among the
              most valuable commodities worldwide. The value of the materials is
              determined by their relative scarcity. In 2018, about 400 metric
              tons of gold were produced in China, the world's largest producer
              of gold. To put this into perspective, the production of crude
              steel reached a staggering 1.8 billion metric tons in the same
              year.<br />
              Silver is both the most abundant and the most affordable precious
              metal. In 2020, Silver prices are projected to hover around 21.5
              U.S. dollars per ounce. At a projected 1,568 U.S. dollars per
              ounce in 2020, the price of platinum is expected to be
              significantly higher, making it the most expensive metal in this
              group.<br />
              The major producing countries for precious metals include Russia,
              the United States, Canada, South Africa and China. With a
              production volume of around 5.52 million ounces, Colorado-based
              Newmont Goldcorp was the world's leading producer of gold in
              2018.<br />
              Demand for the metals is increasing as renewable energy becomes
              more important across the globe, which are important in clean
              energy applications and high-tech industries, are in the
              spotlight, particularly as electric vehicles and hybrid cars gain
              popularity.
            </div>
          </div>
        </div>
      </div>
      <div class="slide-img-holder">
        <img class="slide-img" src="~@/assets/diamond.jpg" />
        <div class="overlay">
          <div class="overlay-text-holder">
            <div class="overlay-text">Diamond Trading</div>
            <div class="overlay-desc">
              Diamond is the hardest material on Earth, and has long-since
              been recognized for its beauty as a gemstone. Major producing
              countries include Australia, Canada, the Democratic Republic of
              Congo, Botswana, South Africa, and Russia. Worldwide reserves are
              estimated to be some 1.2 billion carats. Russia has the largest
              reserves, estimated at some 650 million carats. In contrast to
              precious metals, there is no universal market price per carat of
              diamonds. Nevertheless, global diamond prices have increased more
              than tenfold since 1960 to the prices today.<br> Diamonds also have a
              high industrial value. Around half of all mined diamonds are not
              of gemstone quality and are used for industrial purposes.
              Industrial demand for diamonds is satisfied by diamonds
              synthetic diamonds are also increasingly being used in jewelry as
              an ethical and less expensive alternative to mined diamonds.
            </div>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "Home",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnFocus: true,
        pauseOnHover: true,
      },
    };
  },
  // methods:{

  // }
};
</script>
<style>
.slide-img-holder {
  position: relative;
}

.slide-img {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.7); */
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.overlay-text-holder {
  top: 5%;
  position: relative;
}

.overlay-text {
  color: #eaaf08;
  font-size: 50px;
  width: 100%;
  text-decoration: underline;
  background-color: rgba(0, 0, 0, 0.3);
}

.overlay-desc {
  padding: 20px 140px;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 991px) {
  .overlay-text-holder {
    top: 5%;
  }

  .overlay-desc {
    padding: 5px 15px;
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .overlay-text-holder {
    top: 5%;
  }

  .overlay-text {
    font-size: 25px;
  }

  .overlay-desc {
    padding: 10px;
    font-size: 7px;
  }
}
</style>