<template>
    <div class="form-holder">
        <form @submit="checkForm" method="post">
            <!-- <p v-if="errors.length">
                <b>Please correct the following error(s):</b>
                <ul class="error-list">
                    <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
            </p> -->

            <p class="holder-p" data-aos="flip-left" data-aos-duration="1100">
                <label class="labels" for="name">Name</label><br>
                <input
                class="form-fields"
                id="name"
                v-model="posts.name"
                type="text"
                name="name"
                ><br>
                <span class="error-txt" v-if="n_err.length">*{{ n_err }}</span>
            </p>

            <p class="holder-p" data-aos="flip-right" data-aos-duration="1200">
                <label class="labels" for="email">Email</label><br>
                <input
                class="form-fields"
                id="email"
                v-model="posts.email"
                type="email"
                name="email"
                ><br>
                <span class="error-txt" v-if="e_err.length">*{{ e_err }}</span>
            </p>

            <p class="holder-p" data-aos="flip-left" data-aos-duration="1300">
                <label class="labels" for="phone">Phone</label><br>
                <input
                class="form-fields"
                id="phone"
                v-model="posts.phone"
                type="phone"
                name="phone"
                ><br>
                <span class="error-txt" v-if="p_err.length">*{{ p_err }}</span>
            </p>

            <p class="holder-p" data-aos="flip-right" data-aos-duration="1300">
                <label class="labels" for="subject">Subject</label><br>
                <input
                class="form-fields"
                id="subject"
                v-model="posts.subject"
                type="subject"
                name="subject"
                ><br>
                <span class="error-txt" v-if="s_err.length">*{{ s_err }}</span>
            </p>

            <p class="holder-p" data-aos="flip-left" data-aos-duration="1300">
                <label class="labels" for="msg">Message</label><br>
                <textarea
                rows="5"
                class="form-fields"
                id="msg"
                v-model="posts.msg"
                type="msg"
                name="msg"
                ></textarea><br>
                <span class="error-txt" v-if="m_err.length">*{{ m_err }}</span>
            </p>

            <p data-aos="flip-right" data-aos-duration="1300">
                <input
                class="submit-btn"
                type="submit"
                value="Send"
                >
            </p>
        </form>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
export default {
  name: "Contant",
  data() {
    return{
        errors: [],
        n_err:'',
        e_err:'',
        p_err:'',
        s_err:'',
        m_err:'',
        posts:{
          name: null,
          email: null,
          phone: null,
          subject: null,
          msg: null,
        }
    }
  },
  methods: {
    checkForm(e) {
      this.errors = [];
        this.n_err = '';
        this.e_err = '';
        this.p_err = '';
        this.s_err = '';
        this.m_err = '';

      if (!this.posts.name) {
        this.n_err = "Name required.";
        this.errors.push("Name Error.");
      } else if (this.posts.name.length < 2) {
        this.n_err = "Name must be more than 2 letters.";
        this.errors.push("Name Error.");
      }
      else{
        this.n_err = 0;
      }

      if (!this.posts.email) {
        this.e_err = "Email required.";
        this.errors.push("Email Error.");
      } else if (!this.validEmail(this.posts.email)) {
        this.e_err = "Please enter a valid email address.";
        this.errors.push("Email Error.");
      }
      else{
        this.e_err = 0;
      }

      if (!this.posts.phone) {
        this.p_err = "Phone Number required.";
        this.errors.push("Phone Error.");
      } else if (this.posts.phone.length < 7) {
        this.p_err = "Phone Number must be more than 7 digits.";
        this.errors.push("Phone Error.");
      }
      else{
        this.p_err = 0;
      }

      if (!this.posts.subject) {
        this.s_err = "Subject required.";
        this.errors.push("Subject Error.");
      } else if (this.posts.subject.length < 3) {
        this.s_err = "Subject must be more than 3 letters.";
        this.errors.push("Subject Error.");
      }
      else{
        this.s_err = 0;
      }

      if (!this.posts.msg) {
        this.m_err = "Message required.";
        this.errors.push("Message Error.");
      } else if (this.posts.msg.length < 10) {
        this.m_err = "Message must be more than 10 letters.";
        this.errors.push("Message Error.");
      }
      else{
        this.m_err = 0;
      }

      if (this.n_err == 0 && this.e_err == 0  && this.p_err == 0 && this.s_err == 0 && this.m_err == 0) {
        console.warn(this.posts);
        this.axios.post("http://goldway.ae/mailer/", this.posts, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
        .then(response => {
                console.log('success', response)
            }).catch(error => {
                console.log(error.response)
            });
        e.preventDefault();
      }
      else{
        e.preventDefault();
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style>
.form-holder{
    text-align: left;
}

.labels{
    font-size: 16px;
    padding-left: 5px;
    font-weight: 700;
}

.form-fields{
    border: 3px solid #fff;
    padding: 10px 15px;
    font-size: 16px;
    background: #000;
    border-radius: 6px;
    color: #fff;
    margin-top: 10px;
    width: 85%;
}

.form-fields:focus, .form-fields:hover {
    outline: none;
    border: 3px dashed #EAAF08;
}

.holder-p:focus-within .labels, .holder-p:hover .labels{
    color: #EAAF08;
}

.submit-btn{
    padding: 10px 40px;
    background: #000;
    border: 3px solid #fff;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    border-radius: 6px;
    cursor: pointer;
}

.submit-btn:hover, .submit-btn:focus{
    border: 3px dashed #EAAF08;
    color: #EAAF08;
    outline: none;
}

.error-txt{
    color: #ea1616;
    font-size: 12px;
    padding-left: 5px;
}

.error-list{
    list-style-type: none;
    padding: 0;
    font-size: 14px;
    color: #ea1616;
}
</style>