<template>
  <div>
    <img
      src="~@/assets/goldway-logo.jpeg"
      class="gw-logo"
      data-aos="fade-up"
      data-aos-duration="1500"
    />
    <h1 class="title" data-aos="fade-up" data-aos-duration="1500">
      <b>Goldway Precious Metal Trading LLC</b>
    </h1>
    <p class="desc" data-aos="fade-up" data-aos-duration="1500">
      Goldway Precious Metal Trading LLC is based Dhabi joint venture
      partnership between <b>Al Amry Group of Abu Dhabi and Ploutus Holdings LLC,
      USA.</b> The company was formed to trading in Gold, Diamonds, and other
      precious metals.<br><br> We have been trading in precious metal for over 8 years
      and currently have annual supply contracts with delivery monthly from
      suppliers from Uganda, Ghana, Congo, Kenya and many other countries. We
      specialize in handling large volumes of products and being a Limited
      Liability Company (LLC) in Dubai, we are able to trade with no
      restrictions.<br><br> The Al Amry Group of Companies owns and manages a
      diversified business portfolio, involved in construction, trading,
      automotive supply and services. Since its foundation in 1972, the Group
      has provided the highest quality of products and services through a
      committed, professional management and staff.<br><br> Ploutus Holdings LLC the
      management company of Goldway Precious Metal Trading LLC is an
      Infrastructure development and Finance company. Our areas of expertise are
      in Renewable Energy, buildings of Roads, bridges and other Construction
      projects. Our focus is on the developing countries in Africa, the
      Caribbean and Asia. Our value proposition includes a turnkey solution to
      the governments that we work with including sourcing the finances for the
      projects that we undertake.
    </p>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>
<style>

.gw-logo {
  width: auto;
  max-width: 100%;
}

.title {
  color: #eaaf08;
  font-size: 35px;
}

.desc {
  font-size: 20px;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 25px;
  }

  .desc {
    font-size: 14px;
  }
}
</style>